var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"hidden-md-and-up"},[_c('h1',{staticClass:"title font-weight-medium"},[_vm._v("Perfiles")])]),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.localListUserAccess,"loading":_vm.loading,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"pl-6",attrs:{"items":_vm.users,"item-text":"name","item-value":"id","label":"Usuario","placeholder":"Seleccionar usuario"},on:{"change":function($event){return _vm.searchUser()}},model:{value:(_vm.filter.user_id),callback:function ($$v) {_vm.$set(_vm.filter, "user_id", $$v)},expression:"filter.user_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"pl-6",attrs:{"items":_vm.applications,"clearable":"","item-text":"name","item-value":"id","label":"Aplicación","placeholder":"Seleccionar aplicación"},on:{"change":function($event){return _vm.searchApplication()}},model:{value:(_vm.filter.app_id),callback:function ($$v) {_vm.$set(_vm.filter, "app_id", $$v)},expression:"filter.app_id"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center pr-10",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","disabled":_vm.selectedItemCheck.length ? false : true},on:{"click":function($event){return _vm.deletePermission()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar Permisos ")],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.toggleSelection(item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}},{key:"item.user_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_active ? 'ACTIVO' : 'INACTIVO')+" ")]}},{key:"item.assigned_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assigned_by ? item.assigned_by : '-')+" ")]}},{key:"item.costCenter_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? 'ACTIVO' : 'INACTIVO')+" ")]}},{key:"item.assignment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.assignment_date))+" ")]}}],null,true)})],1)],1),_c('br'),(_vm.listUserAccessPagination.pages > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.listUserAccessPagination.pages,"total-visible":7},on:{"input":function () { return _vm.searchUser(); }},model:{value:(_vm.filter.page),callback:function ($$v) {_vm.$set(_vm.filter, "page", $$v)},expression:"filter.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }